import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
import Nprogress from 'nprogress'
import './style/nprogress.css';

import giftyRoutes from './router/giftyRoutes'

Vue.use(VueRouter)

const routes = [
    {
        path: '*',
        name: 'notFound',
        component: () => import('./views/notFound'),
        meta: {
            title: 'Page not found'
        }
    },
    {
        path: '/401',
        name: '401',
        component: () => import('./views/401'),
        meta: {
            title: '401'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./views/auth/login'),
        meta: {
            title: 'Connexion'
        }
    },
    {
        path: '/e-payment/:token/external',
        name: 'external.payment',
        component: () => import('./views/qr_code/index.vue'),
        meta: {
            title: 'Payé avec Gifty DZ'
        }
    },
    {
        path: '/myAccount',
        name: 'myAccount',
        component: () => import('./views/account'),
        meta: {
            title: 'Mon compte'
        }
    },
    {
        path: '/database',
        name: 'database',
        component: () => import('./views/database'),
        meta: {
            title: 'Base de données',
            roles: ['admin', 'supplier', 'client']
        }
    },
    {
        path: '/database/:id',
        name: 'database.show',
        component: () => import('./views/database/show'),
        meta: {
            title: 'Base de données',
            roles: ['admin', 'supplier', 'client']
        }
    },
    {
        path: '/database/commissions/:id/:name',
        name: 'commissions.show',
        component: () => import('./views/commissions/show'),
        meta: {
            title: 'Details Commissions Utilisateur',
            roles: ['admin', 'supplier', 'client']
        }
    },

    /**
     ** Campaigns**
     **/
    {
        path: '/campaigns',
        name: 'campaigns',
        component: () => import('./views/campaigns'),
        meta: {
            title: 'Campagnes',
            roles: ['admin', 'supplier', 'client']
        }
    },
    {
        path: '/campaigns/create',
        name: 'campaigns.create',
        component: () => import('./views/campaigns/create'),
        meta: {
            title: 'Campagnes | Nouvelle campagne',
            roles: ['admin', 'supplier', 'client']
        }
    },
    {
        path: '/campaigns/:id/duplicate',
        name: 'campaigns.duplicate',
        component: () => import('./views/campaigns/create'),
        meta: {
            title: 'Campagnes | Dupliquer un campagne',
            roles: ['admin', 'supplier', 'client']
        }
    },


    /**
     ** Recoveries**
     **/

    {
        path: '/recoveries',
        name: 'recoveries',
        component: () => import('./views/recoveries'),
        meta: {
            title: 'Recouvrements',
            roles: ['admin', 'supplier']
        }
    },
    {
        path: '/recoveries/recuperators',
        name: 'recuperators',
        component: () => import('./views/recoveries/recuperators'),
        meta: {
            title: 'Récupérateurs',
            roles: ['admin', 'supplier']
        }
    },
    {
        path: '/recoveries/statistics',
        name: 'recoveries.statistics',
        component: () => import('./views/recoveries/statistics'),
        meta: {
            title: 'Statistiques',
            roles: ['admin', 'supplier']
        }
    },
    {
        path: '/recoveries/payments',
        name: 'recoveries.payments',
        component: () => import('./views/recoveries/payments'),
        meta: {
            title: 'Paiements',
            roles: ['admin']
        }
    },
    {
        path: '/recoveries/config',
        name: 'recoveries.config',
        component: () => import('./views/recoveries/config'),
        meta: {
            title: 'Configuration',
            roles: ['admin']
        }
    },
    {
        path: '/partner-vouchers',
        name: 'partner.vouchers',
        component: () => import('./views/partner_vouchers/index'),
        meta: {
            title: 'Boutiques partenaire',
            key: 'partnerVouchers',
            roles: ['store', 'supplier']
        }
    },
    {
        path: '/partner-vouchers/store/:id',
        name: 'partner.vouchers.store',
        component: () => import('./views/partner_vouchers/index'),
        meta: {
            title: 'Boutiques partenaire',
            key: 'partnerVouchers',
            roles: ['store', 'supplier']
        }
    },
    {
        path: '/partner-vouchers/store/:id/scan',
        name: 'partner.vouchers.scan',
        component: () => import('./views/partner_vouchers/scan'),
        meta: {
            title: 'Boutiques partenaire - scan',
            key: 'partnerVouchers',
            roles: ['store', 'supplier']
        }
    },
    {
        path: '/partner-vouchers/stores',
        name: 'partner.vouchers.stores',
        component: () => import('./views/partner_vouchers/stores'),
        meta: {
            title: 'Boutiques partenaire - stores',
            key: 'partnerVouchers',
            roles: ['supplier']
        },
    },
    {
        path: '/partner-vouchers/statistics',
        name: 'partner.vouchers.statistics',
        component: () => import('./views/partner_vouchers/statistics.vue'),
        meta: {
            title: 'Boutiques partenaire - statistiques',
            key: 'partnerVouchers',
            roles: ['supplier']
        },
    },
    {
        path: '/partner-vouchers/store/:id/scan-history',
        name: 'partner-vouchers.sacn-history',
        component: () => import('./views/partner_vouchers/scan-history/index'),
        meta: {
            title: 'Boutiques partenaire - Historique des scans',
            roles: ['supplier', 'store']
        }
    },
    {
        path: '/partner-vouchers/store/:id/payment-history',
        name: 'partner-vouchers.payment-history',
        component: () => import('./views/partner_vouchers/payment-history'),
        meta: {
            title: "Boutiques partenaire - Historique des paiements",
            roles: ['supplier', 'store']
        }
    },

    {
        path: '/transactions',
        name: 'transaction',
        component: () => import('./views/transaction'),
        meta: {
            title: 'Transactions',
            roles: ['admin', 'supplier', 'client']
        }
    },
    {
        path: '/services',
        name: 'serives',
        component: () => import('./views/service'),
        meta: {
            title: 'Services',
            roles: ['admin']
        }
    },

    {
        path: '/voucher-order',
        name: 'Commande Bon d\'achat',
        component: () => import('./views/voucher_orders'),
        meta: {
            title: 'Services',
            roles: ['admin', 'support', 'order_manager']
        }
    },
    {
        path: '/',
        name: 'home',
        component: () => import('./views/dashboard'),
        meta: {
            title: 'Dashboard',
            roles: ['admin']
        }
    },
    {
        path: '/analyses',
        name: 'analyses',
        component: () => import('./views/analyses'),
        meta: {
            title: 'Analyses',
            roles: ['admin']
        }
    },
    {
        path: '/partner-analyses',
        name: 'partner.analyses',
        component: () => import('./views/partner_analyses/index'),
        meta: {
            title: 'analyses partenaires',
            roles: ['admin']
        }
    },
    {
        path: '/vouchers',
        name: 'vouchers',
        component: () => import('./views/vouchers'),
        meta: {
            title: 'Vouchers',
            roles: ['admin', 'supplier', 'order_manager']
        }
    },
    {
        path: '/analyses_vouchers',
        name: 'analyses_vouchers',
        component: () => import('./views/vouchers/analyses'),
        meta: {
            title: 'Analyses Vouchers',
            roles: ['admin', 'supplier']
        }
    },

    {
        path: '/analyses_vouchers/show/:id',
        name: 'analyses_vouchers.show',
        component: () => import('./views/vouchers/analyses/show'),
        meta: {
            title: 'Analyses Vouchers',
            roles: ['admin', 'supplier']
        }
    },

    {
        path: '/analyses_vouchers/drivers',
        name: 'analyses_vouchers.drivers',
        component: () => import('./views/vouchers/analyses/drivers'),
        meta: {
            title: 'Analyses Vouchers',
            roles: ['admin', 'supplier']
        }
    },

    {
        path: '/analyses_vouchers/stock',
        name: 'analyses_vouchers.stock',
        component: () => import('./views/vouchers/stock'),
        meta: {
            title: 'Stock Vouchers',
            roles: ['admin', 'supplier', 'order_manager']
        }
    },

    {
        path: '/analyses_vouchers/show_driver/:phone',
        name: 'analyses_vouchers.show_driver',
        component: () => import('./views/vouchers/analyses/showDriver'),
        meta: {
            title: 'Analyses Vouchers',
            roles: ['admin', 'supplier']
        }
    },

    {
        path: '/services',
        name: 'services',
        component: () => import('./views/service'),
        meta: {
            title: 'Services',
            roles: ['admin']
        }
    },
    {
        path: '/services/types',
        name: 'services_types',
        component: () => import('./views/serviceTypes'),
        meta: {
            title: 'Type Services',
            roles: ['admin']
        }
    },
    {
        path: '/card_types',
        name: 'cardstypes',
        component: () => import('./views/typeCards'),
        meta: {
            title: 'Cartes de recharge',
            roles: ['admin']
        }
    },
    {
        path: '/cards',
        name: 'cards',
        component: () => import('./views/cards'),
        meta: {
            title: 'Cartes de recharge',
            roles: ['admin']
        }
    },

    {
        path: '/publicities',
        name: 'publicities',
        component: () => import('./views/publicities'),
        meta: {
            title: 'Pulblicités',
            roles: ['admin', 'supplier', 'client']
        }
    },
    {
        path: '/publicities/analyse/:id',
        name: 'publicities.analyses',
        component: () => import('./views/publicities/analyse'),
        meta: {
            title: "Analyse d'une Pulblicité",
            roles: ['admin', 'supplier', 'client']
        }
    },
    // {
    //     path: '/facturations',
    //     name: 'facturations',
    //     component: () => import('./views/facturations'),
    //     meta: {
    //         title: 'Facturations',
    //         roles: ['admin', 'supplier']
    //     }
    // },
    // {
    //     path: '/facturations/:id',
    //     name: 'facturations.show',
    //     component: () => import('./views/facturations/show'),
    //     meta: {
    //         title: 'Details Facture',
    //         roles: ['admin', 'supplier']
    //     }
    // },
    // {
    //     path: '/facturation_client',
    //     name: 'facturation_client',
    //     component: () => import('./views/facturation_client'),
    //     meta: {
    //         title: 'Facturations Client',
    //         roles: ['admin', 'supplier', 'client']
    //     }
    // },
    // {
    //     path: '/facturation_client/:id',
    //     name: 'facturation_client.show',
    //     component: () => import('./views/facturation_client/show'),
    //     meta: {
    //         title: 'Details Facturations Client',
    //         roles: ['admin', 'supplier', 'client']
    //     }
    // },
    {
        path: '/support',
        name: 'support',
        component: () => import('./views/support'),
        meta: {
            title: 'Support',
            roles: ['admin', 'support', 'order_manager']
        }
    },
    {
        path: '/support/scan-history',
        name: 'support.scan-history',
        component: () => import('./views/support/scan-history/index'),
        meta: {
            title: 'Support - Historiques des scans',
            roles: ['admin', 'support']
        }
    },
    {
        path: '/referral-codes',
        name: 'referralCodes',
        component: () => import('./views/referralCodes/index.vue'),
        meta: {
            title: 'Codes de parrainage',
            roles: ['admin']
        }
    },
    {
        path: '/users',
        name: 'users',
        component: () => import('./views/users'),
        meta: {
            title: 'users',
            roles: ['admin', 'client', 'supplier', 'support', 'supplier_manager']
        }
    },
    {
        path: '/users/settings',
        name: 'users.settings',
        component: () => import('./views/users/settings'),
        meta: {
            title: 'settings',
            roles: ['admin']
        }
    },
    {
        path: '/users/:account_id',
        name: 'users.show',
        component: () => import('./views/users/show'),
        meta: {
            title: 'users',
            roles: ['admin', 'support']
        }
    },
    {
        path: '/client',
        name: 'users.client',
        component: () => import('./views/client'),
        meta: {
            title: 'client',
            roles: ['client']
        }
    },

    {
        path: '/settings',
        name: 'settings',
        component: () => import('./views/settings'),
        meta: {
            title: 'settings',
            roles: ['admin']
        }
    },

    {
        path: '/balance',
        name: 'balance',
        component: () => import('./views/balance'),
        meta: {
            title: 'Balance',
            roles: ['admin']
        }
    },

    {
        path: '/anomalies',
        name: 'anomaly',
        component: () => import('./views/anomaly'),
        meta: {
            title: 'Anomalies',
            roles: ['admin']
        }
    },
    {
        path: '/anomaly/cards',
        name: 'anomaly.cards',
        component: () => import('./views/anomaly/cardsMenu.vue'),
        meta: {
            title: 'Anomalies martschip',
            roles: ['admin']
        }
    },
    {
        path: '/boostings',
        name: 'boostings',
        component: () => import('./views/boostings/index.vue'),
        meta: {
            title: 'Boostings',
            roles: ['booster']
        }
    },
    // {
    //     path: '/anomaly/cardsSms',
    //     name: 'anomaly',
    //     component: () => import('./views/anomaly/cardsSms'),
    //     meta: {
    //         title: 'Anomalies SMS Cartes',
    //         roles: ['admin']
    //     }
    // },

    // {
    //     path: '/anomaly/consumedCards',
    //     name: 'anomaly',
    //     component: () => import('./views/anomaly/consumedCards'),
    //     meta: {
    //         title: 'Anomalies Cartes',
    //         roles: ['admin']
    //     }
    // },


    {
        path: '/balance/history',
        name: 'anomaly.balance.history',
        component: () => import('./views/account/historyBalance'),
        meta: {
            title: 'History Balance',
            roles: ['admin']
        }
    },

    {
        path: '/balance/history/:id',
        name: 'anomaly.history.show',
        component: () => import('./views/account/showHistoryBalance'),
        meta: {
            title: 'History Balance',
            roles: ['admin']
        }
    },

    /**
     *  Fees routes **
     **/

    {
        path: '/fees',
        name: 'fees',
        component: () => import('./views/fees'),
        meta: {
            title: 'Frais de service',
            roles: ['admin']
        }
    },
    {
        path: '/fees/create',
        name: 'fees.create',
        component: () => import('./views/fees/create.vue'),
        meta: {
            title: 'Ajouter un frai de service',
            roles: ['admin']
        }
    },
    {
        path: '/fees/:id/update',
        name: 'fees.edit',
        component: () => import('./views/fees/create.vue'),
        meta: {
            title: 'Modifier un frai de service',
            roles: ['admin']
        }
    },


    /**
     *  Promotions routes **
     **/

    {
        path: '/promotions',
        name: 'promotions',
        component: () => import('./views/promotions'),
        meta: {
            title: 'Promotions',
            roles: ['admin']
        }
    },
    {
        path: '/promotions/create',
        name: 'promotions.create',
        component: () => import('./views/promotions/create.vue'),
        meta: {
            title: 'Ajouter une promotion',
            roles: ['admin']
        }
    },
    {
        path: '/promotions/:id/update',
        name: 'promotions.edit',
        component: () => import('./views/promotions/create.vue'),
        meta: {
            title: 'Modifier une promotion',
            roles: ['admin']
        }
    },


    /**
     *  cashback routes **
     **/

    {
        path: '/cashback',
        name: 'cashback',
        component: () => import('./views/cashback'),
        meta: {
            title: 'Cashback',
            roles: ['admin']
        }
    },
    {
        path: '/cashback/create',
        name: 'cashback.create',
        component: () => import('./views/cashback/create.vue'),
        meta: {
            title: 'Ajouter une cashback',
            roles: ['admin']
        }
    },
    {
        path: '/cashback/:id/update',
        name: 'cashback.edit',
        component: () => import('./views/cashback/create.vue'),
        meta: {
            title: 'Modifier une cashback',
            roles: ['admin']
        }
    },


    /**
     *  Quotas routes **
     **/

    {
        path: '/quotas',
        name: 'quotas',
        component: () => import('./views/quotas'),
        meta: {
            title: 'quotas',
            roles: ['admin']
        }
    },
    {
        path: '/quotas/create',
        name: 'quotas.create',
        component: () => import('./views/quotas/create.vue'),
        meta: {
            title: 'Ajouter un quota',
            roles: ['admin']
        }
    },
    {
        path: '/quotas/:id/update',
        name: 'quotas.edit',
        component: () => import('./views/quotas/create.vue'),
        meta: {
            title: 'Modifier un quota',
            roles: ['admin']
        }
    },

    /**
     *  Promo codes routes **
     **/

    {
        path: '/promo-codes',
        name: 'promo_codes',
        component: () => import('./views/promo_codes'),
        meta: {
            title: 'Codes promo',
            roles: ['admin']
        }
    },

    {
        path: '/promo-codes/create',
        name: 'promo_codes.create',
        component: () => import('./views/promo_codes/create.vue'),
        meta: {
            title: 'Ajouter un code promo',
            roles: ['admin']
        }
    },

    {
        path: '/promo-codes/:id/update',
        name: 'promo_codes.edit',
        component: () => import('./views/promo_codes/create.vue'),
        meta: {
            title: 'Modifier un code promo',
            roles: ['admin']
        }
    },

    /***
     * E-payments **
     * **/
    {
        path: '/e-payment/:token/checkout',
        name: 'e_payment.checkout',
        component: () => import('./views/e_payment/index.vue'),
        meta: {
            title: 'E-Paiemen - Checkout'
        }
    },


    {
        path: '/e-payment/:token/message',
        name: 'e_payment.message',
        component: () => import('./views/e_payment/message.vue'),
        meta: {
            title: 'E-Paiement - Message',
        }
    },

    {
        path: '/e-payments',
        name: 'e_payments',
        component: () => import('./views/e_payments'),
        meta: {
            title: 'E-Paiements',
            roles: ['admin', 'support']
        }
    },
    {
        path: '/paying-bills',
        name: 'paying-bills',
        component: () => import('./views/paying_bills'),
        meta: {
            title: 'Paiement des factures',
            roles: ['admin', 'support', 'order_manager']
        }
    },
    {
        path: '/occasion_cards',
        name: 'occasion_cards',
        component: () => import('./views/occasion_cards'),
        meta: {
            title: 'Carte d\'occasions',
            roles: ['admin']
        }
    },

    {
        path: '/occasion_cards/categories',
        name: 'occasion_categories',
        component: () => import('./views/occasion_cards/category'),
        meta: {
            title: 'Categories Carte d\'occasions',
            roles: ['admin']
        }
    },

    {
        path: "/e-payment/terms",
        name: "e_payments.terms",
        component: () => import('./views/e_payment/Terms.vue'),
        meta: {
            title: 'Conditions de remboursement'
        }

    },
    /**END**/

    {
        path: '/forgot_password',
        name: 'forgot_password',
        component: () => import('./views/gifty/ForgotPassword.vue'),
        meta: {
            title: 'Mot de passe oublié'
        }
    },
    {
        path: "/notices",
        name: "notices",
        component: () => import('./views/notices/index.vue'),
        meta: {
            title: 'Notifications'
        }
    },

    {
        path: '/disable_requests',
        name: 'disable_requests',
        component: () => import('./views/users/DisableRequests.vue'),
        meta: {
            title: 'Demande de suppression comptes',
            roles: ['admin']
        }
    },

    {
        path: '/refill/invitations',
        name: 'refill.invitations',
        component: () => import('./views/invitations'),
        meta: {
            title: 'Invitations',
            roles: ['admin']
        }
    },

    {
        path: '/service/commissions',
        name: 'commissions',
        component: () => import('./views/commissions'),
        meta: {
            title: 'Commissions',
            roles: ['admin']
        }
    },
    {
        path: '/fees/types',
        name: 'types',
        component: () => import('./views/fees/types'),
        meta: {
            title: 'Type Frais',
            roles: ['admin']
        }
    },
    {
        path: '/supplier-manager-services',
        name: 'supplier.manager.services',
        component: () => import('./views/supplier_manager/services/index.vue'),
        meta: {
            title: 'Services',
            roles: ['supplier_manager']
        }
    },
    {
        path: '/supplier-manager-services/create',
        component: () => import('./views/supplier_manager/services/create.vue'),
        meta: {
            title: 'Ajouter un service',
            roles: ['supplier_manager']
        }
    },
    {
        path: '/supplier-manager-services/:id/update',
        component: () => import('./views/supplier_manager/services/create.vue'),
        meta: {
            title: 'Modifier un service',
            roles: ['supplier_manager']
        }
    },
    {
        path: '/m/statistics',
        component: () => import('./views/mobileStat.vue'),
        meta: {
            title: 'Statistiques',
        }
    },
    {
        path: '/delete-account',
        component: () => import('./views/deleteAccount.vue'),
        meta: {
            title: 'Supprimer mon compte GIFTY',
        }
    },
    {
        path: '/report-errors',
        component: () => import('./views/report_errors/public'),
        meta: {
            title: 'signaler des erreurs',
        }
    },
    {
        path: '/errors-reported',
        component: () => import('./views/report_errors/index'),
        meta: {
            title: 'Poblèmes signalées',
        }
    },
    {
        path: '/errors-reported/list-errors',
        component: () => import('./views/report_errors/errors/index'),
        meta: {
            title: 'Listes des problèmes',
        }
    },
    {
        path: '/game',
        component: () => import('./views/game/index'),
        meta: {
            title: 'Jouer et gagner avec GIFTY',
        }
    },
    {
        path: '/game/AZERPTJ5845FL',
        component: () => import('./views/game/statistic.vue'),
        meta: {
            title: 'Game statistics',
        }
    },
]

var allRoutes = []
allRoutes = allRoutes.concat(routes, giftyRoutes)

function isNewStore() {
    var user = store.getters.getUser.user;
    if (user.type === "salepoint") {
        if (user.salepoint == null) {
            return true;
        }

    }
    return false;
}

const router = new VueRouter({
    mode: 'history',
    routes: allRoutes,
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        } else if (to.hash) {
            return {
                selector: to.hash
            };
        } else {
            return {x: 0, y: 0};
        }
    }
})

router.beforeEach((to, from, next) => {

    /**
     * Check Is logged in**
     **/

    const publicPages = [
        '/game/AZERPTJ5845FL',
        '/game',
        '/report-errors',
        '/delete-account',
        '/login',
        '/forgot_password',
        '/e-payment/:token/checkout',// Checkout page
        '/e-payment/:token/message', // Success order page
        '/gifty/register',
        '/gifty/register-particulier',
        '/gifty/register-professionel',
        '/terms',
        '/e-payment/:token/external',//Generate payment qr code
        '/m/statistics'
    ];

    const no_edirect = [
        '/e-payments/message',
    ];

    const authRequired = !publicPages.includes(to.path);
    const loggedIn = store.state.isLoggedIn;

    if (authRequired && !loggedIn && !to.path.startsWith("/e-payment/")) {
        next('/login');
    } else if (!authRequired && loggedIn && !no_edirect.includes(to.path) && !to.path.startsWith("/e-payment/")) {
        next(store.state.home);
    } else {
        if (loggedIn) {
            store.dispatch("fetchAccount");
        }
        if (to.path === "/" && store.state.home !== "/") {
            if (isNewStore()) {
                next('/gifty/salepoint');
            } else {
                next(store.state.home);
            }
        } else {
            next()
        }
    }

    /**
     * Page title**
     **/
    document.title = process.env.VUE_APP_NAME + '::' + to.meta.title

    /**
     * Start Top progress**
     **/
    if (to.name) {
        Nprogress.start()
    }
});


/**
 * roles**
 **/
router.beforeEach((to, from, next) => {
    if (to.meta.roles) {
        const roles = to.meta.roles
        const roleName = store.state.user.roleName
        const state = !!roles.includes(roleName)
        if (state) {
            next();
        } else {
            next('/401');
        }
    } else {
        next()
    }
})


/**
 *End Top progress**
 **/
router.afterEach(() => {
    setTimeout(function () {
        Nprogress.done()
    }, 500)
})

export default router
